import React, { useEffect } from "react";
import { useRouter } from "next/router";

const Login = () => {
  const router = useRouter()
  useEffect(()=>{
if (!router.query.code) {
  router.push('/')
}
  })
  return (
    <div>
      loading...
    </div>
  );
};

export default Login;
